<template>
  <head>
    <title>お気に入り・購入履歴</title>
  </head>
  <aheader/>
  <div class="block">
    <section class="hero is-warning">
      <div class="hero-body">
        <p class="title">
          お気に入り・購入履歴
        </p>
        <p class="subtitle">
          メインページ
        </p>
      </div>
    </section>
  </div>
  <div class="block">
    <section class="hero is-small is-white">
      <div class="hero-body" style="text-align:left">
        <p class="title">
          お気に入りの商品
        </p>
        <div class="columns">
          <div class="column">
            <div class="bulma-clearfix-mixin">
              <div class="block">
                <img height="209" width="300" style="float: top;" src="kari3.jpg">
              </div>
              <div class="block">
                <p>商品名・価格・説明(少)</p>
              </div>
            </div>
          </div>
          <div class="column">
            <div class="bulma-clearfix-mixin">
              <div class="block">
                <img height="209" width="300" style="float: top;" src="kari3.jpg">
              </div>
              <div class="block">
                <p>商品名・価格・説明(少)</p>
              </div>
            </div>
          </div>
          <div class="column">
            <div class="bulma-clearfix-mixin">
              <div class="block">
                <img height="209" width="300" style="float: top;" src="kari3.jpg">
              </div>
              <div class="block">
                <p>商品名・価格・説明(少)</p>
              </div>
            </div>
          </div>
          <div class="column">
            <div class="bulma-clearfix-mixin">
              <div class="block">
                <img height="209" width="300" style="float: top;" src="kari3.jpg">
              </div>
              <div class="block">
                <p>商品名・価格・説明(少)</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
  <div class="block">
    <section class="hero is-small is-white">
      <div class="hero-body" style="text-align:left">
        <p class="title">
          購入履歴
        </p>
        <div class="columns">
          <div class="column">
            <div class="bulma-clearfix-mixin">
              <div class="block">
                <img height="209" width="300" style="float: top;" src="kari3.jpg">
              </div>
              <div class="block">
                <p>商品名・価格・説明(少)</p>
              </div>
            </div>
          </div>
          <div class="column">
            <div class="bulma-clearfix-mixin">
              <div class="block">
                <img height="209" width="300" style="float: top;" src="kari3.jpg">
              </div>
              <div class="block">
                <p>商品名・価格・説明(少)</p>
              </div>
            </div>
          </div>
          <div class="column">
            <div class="bulma-clearfix-mixin">
              <div class="block">
                <img height="209" width="300" style="float: top;" src="kari3.jpg">
              </div>
              <div class="block">
                <p>商品名・価格・説明(少)</p>
              </div>
            </div>
          </div>
          <div class="column">
            <div class="bulma-clearfix-mixin">
              <div class="block">
                <img height="209" width="300" style="float: top;" src="kari3.jpg">
              </div>
              <div class="block">
                <p>商品名・価格・説明(少)</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
  <afooter/>
</template>
